@font-face {
    font-family: spaceman;
    src: url('./media/fonts/spaceman-webfont.woff2') format('woff2');
}
@font-face {
    font-family: Louis;
    src: url('./media/fonts/louis_george_cafe-webfont.woff2')  format('woff2');
}

@font-face {
    font-family: Nechao;
    src: url('./media/fonts/nechao_sharp-webfont.woff2')  format('woff2');
}

body {
    margin: 0px;
    padding: 0px;
    font-family: Louis,ldcount,fabricate,spaceman,ldrregular,Nechao,sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #000;
}


*, *::before, *::after {
    scrollbar-color: #241528 #fff;
    box-sizing: inherit;
}

.navbar {
    position: absolute;
    top : 0px;
    left: 50%;
    width:100%;
    transform: translateX(-50%);
    z-index: 10000;
    background: linear-gradient(180deg, rgba(9,13,47,1) 0%, rgba(9,13,47,1) 75%, rgba(9,13,47,1) 100%);
}

MuiBackdrop {
    background-color: rgba(0,0,0,0.2);
    z-index:  1000;
    color: #fff;
}

.navbar-drawer-header {
    display: flex;
    text-align: center;
    background-color: transparent;
    width: auto;
    padding-right: 10px;
    padding-left: 10px;
    align-items: center;
    height: 60px;
}

.video-bottom-bar{
    width: 100%;
    height: 90px;
    position: absolute;
    bottom :108px;
    
    background: linear-gradient(360deg, rgb(9 13 47 / 32%) 0%, rgb(9 13 47 / 97%) 49%, rgb(9 13 47 / 0%) 88%);
}

.links {
    height: auto;
    background-color: transparent;
    width: auto;
    display: flex;
    color: #fff;
    padding-top: 15px;
    font-family: Nechao;
    justify-content: space-around;

    /* clip-path: polygon(0% 100%, 100% 100%, 80% 80px, calc(100% - 30px) 0%, 0% 0%); */
}

.navbar-item {
    cursor: pointer;
    padding-top: 26px;
    text-decoration: none;
    color:#b8a0f9;
    font-size: 15px;
    font-weight: 10;
    vertical-align: middle;
}
.navbar-item-text {
    color:#b8a0f9;
    padding-top: 32px;
    text-decoration: none;
    color:#b8a0f9;
    font-size: 15px;
    font-weight: 10;
    vertical-align: middle;
    
}

.navbar-drawer-item { 
    cursor: pointer;
    text-decoration: none;
    color:#fff;
    text-align: center;
}

.navbar-item:hover {
    background-color: transparent;
    animation: fade 1s;
}

/* HOME */

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-blur {
    backdrop-filter: blur(5px);
}

.background-video-container-big {
    
    height: 700px; 
    width: 100%; 
    overflow: hidden;
    background-color: #000;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;

}

.background-video {
    width: 100%; 
  height: 700px; 
  left : 0px;
  position: absolute;
  text-align: center;
  object-fit: cover;


}

.background-video-small {
    width: 100%; 
 
  left : 0px;
  position: absolute;
  text-align: center;


}

.background-video-container-small {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 700px; /* Fixed height */
    width: 100%; /* Full width */
    overflow: hidden; /* Hide overflow */
    position: fixed; /* Needed for proper positioning */
}



.content-container {
    position: absolute;
    top: 20px;
    left: 10%;
    z-index: 10001;
}

.content-grid-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.content-grid-section { 
    position: absolute;
    min-height: 600px;
    flex: 1;
    justify-content: flex-end;
}

.content-grid-section-container-big {
    margin-top: 90px;
    margin-bottom: 0;
    text-align: center;
    color: #fff;
    padding-right: 10%;
    justify-content: flex-start;
}

.content-grid-section-container-small {
    margin-top: 30px;
    text-align: center;
    color: #fff;
    justify-content: flex-start;
    align-items: center;
}

.content-grid-section-chests {
    justify-content: flex-start;
    align-items: flex-start;
    height: 900px;
    background-size: cover;
}

.content-grid-section-partners {
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    width: 100%;
    text-align: center;
    min-height: 100px;

    background-color: #000257

}

.root-div { 
    background-color: #000000;
    height: 100vh;
    width: 100%;
}

.content-divider-small { 
    position: absolute;
    height: 300px;
    background: linear-gradient(rgb(107 117 191 / 0%) 90%, #445362 100%);
    width: 100%;
}

/* TEAM */

.team-member {
    margin-bottom: 30px;
}

.team-member-img {
    border-radius: 50%;
    margin: 0 auto;
    cursor: pointer;
}

.team-member-name { 
    margin-top: 10px;
    font-size: 14px;
    width: 100%;
    text-shadow: 1px 1px 3px #000;
}

.team-member-role { 
    margin-top: 0px;
    font-size: 12px;
    width: 100%;
    text-shadow: 1px 1px 3px #000;
}

.team-member-img:hover {
    animation: bounce 1s;
}

.team-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000eb !important;
    z-index: 11000;
}

.team-modal-fade { 
    max-width: 500px;
    width: 90%;
    background-color: rgb(0 53 76 / 93%);
    border: 0px solid;
    position: absolute;
    top: 100px;
    color: #fff;
    border-radius: 13px;
    text-align: center;
    padding-top: 20px;
}

.team-modal-fade-image {
    border-radius: 50%;
    height: 100px;
    text-align: right;
    margin: 0 auto;
    cursor: pointer;
}

.team-modal-fade-name {
    margin-top: 10px;
    font-size: 28px;
    width: 100%
}

.team-modal-fade-position {
    margin-top: 0px;
    font-size: 16px;
    width: 100%
}

.team-modal-fade-desc {
    padding: 30px;
    text-justify: inter-word
}

/* PARTNERS */
.partner-img {
    width: 100;
    height: 100;
    aspect-ratio:inherit;
    margin: 0 auto;
    cursor: pointer;
}


/* TOURNAMENTS */
.tournament-card {
    border: 3px solid;
    border-color: #fff;
    border-radius: 30px;
    margin: 10px;
    width: 360px;
    height: 200px;
    padding: 15px;
    background: radial-gradient(67.31% 67.31% at 49.69% 68.98%, rgba(64, 221, 255, 0.8) 1%, rgba(0, 87, 106, 0.8) 100%, rgba(0, 87, 106, 0.8) 100%);
    justify-content: center;
}


.tournament-card-content {
    position: relative;
    z-index: 1;
}

.tournament-card-image {
    position: relative;
    width: 180px;
    bottom: 160px;
    left: 160px;
    z-index: 0;
}

.tournament-card-timer { 
    color: #fff;
    font-family: Louis;
}

.tournament-card-timer-full { 
    color: #fff;
    font-family: Louis;
    font-size: 22px;
}

.tournament-card-image-full {
    justify-content: center;
    border: 3px solid;
    border-color: #fff;
    border-radius: 50px;
    width: 300px;
}

.tournament-card-button {
    position: absolute;
}

.tournament-card-title {
    color: #fff;
    font-family: 'Louis';
    font-size: 24px;
}

.tournament-card-title-full {
    color: #fff;
    font-size: 24px;
    font-family: spaceman;
    text-align: center;
}

.tournament-card-text {
    color: #fff;
    font-family: 'Louis';
    font-size: 18px;
}

.tournament-status-ongoing { 
    color: #20E100;
}

.tournament-status-upcoming { 
    color: #FDE511;
}

.tournament-status-finished { 
    color: #E90000;
}

.tournament-card-full {
    border: 3px solid;
    border-color: #fff;
    border-radius: 30px;
    padding: 20px;
    margin: 10px;
    overflow: hidden;
}

.tournament-full { 
    height: 100vh;
    width: inherit;
    margin-top: 10vh;
}

/* ANIMATIONS */
@keyframes fade {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}

@keyframes bounce {
    0%, 20%, 60%, 100% {
      box-shadow: none;
      transform: scale(1);
    }
  
    40% {
        transform: scale(1.2);
        filter: drop-shadow(0px 0px 0px #00000063); 
        opacity: 0.8;
    }
  
    80% {
        box-shadow: none;
        transform: scale(1);
    }
}